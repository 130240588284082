import { Stack, Typography } from "@mui/material";

export default function RSVPSubmitted() {
    return (
        <Stack sx={{ mt: "40vh" }}>
            <Typography align="center" variant="h3" color="secondary.main">
                Thank you for submitting your RSVP!
            </Typography>
            <Typography align="center" variant="h3" color="secondary.light">
                You will NOT get a confirmation email, but we will send updates closer to the wedding
            </Typography>
        </Stack>
    )
}