import { Container, Typography, Link } from "@mui/material";
import CampMapImage from "images/camp_map.jpg";

export default function CampMap() {
  return (
    <>
      <Typography variant="h3" color="secondary" align="center" gutterBottom>
        Camp Map
      </Typography>
      <Container sx={{ marginBottom: 1 }}>
        <img
          alt="Camp Somos"
          src={CampMapImage}
          style={{ borderRadius: 20, width: "100%", cursor: "pointer" }}
          onClick={() => window.open(CampMapImage, "_blank").focus()}
        />
      </Container>
      <div
        style={{
          marginBottom: 32,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Link
          href="https://www.teentime.ab.ca/facilities"
          variant="h5"
          color="secondary.light"
          underline="hover"
          target="_blank"
        >
          Camp Info Link
        </Link>
      </div>
    </>
  );
}
