import { useEffect, useState } from "react";
import {
  ImageList,
  ImageListItem,
  useMediaQuery,
  Skeleton,
  Typography,
} from "@mui/material";
import "./Photos.css";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const localPhotos = [
  {
    key: "https://as1.ftcdn.net/v2/jpg/02/20/80/30/500_F_220803033_x6Oci7G6zKKfjqgsl9gBn3dpOyLdZmTN.jpg",
  },
  {
    key: "https://brandingforthepeople.com/wp-content/uploads/2019/04/Stock-Photography-vs-Real-Imagery.jpg",
  },
  {
    key: "https://media.istockphoto.com/id/1487894858/photo/candlestick-chart-and-data-of-financial-market.jpg?s=612x612&w=0&k=20&c=wZ6vVmbm4BV2JOePSnNNz-0aFVOJZ0P9nhdeOMGUg5I=",
  },
  {
    key: "https://previews.123rf.com/images/gdolgikh/gdolgikh1504/gdolgikh150400087/38738606-group-of-happy-young-people-isolated-on-white-background.jpg",
  },
];

export default function Photos({ bucket_name, title }) {
  const [photos, setPhotos] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  const matches = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    switch (process.env.NODE_ENV) {
      case "production":
        fetch(`/${bucket_name}/list`)
          .then((res) => res.json())
          .then((data) => {
            data = (data || [])
              .map((value) => ({ value, sort: Math.random() }))
              .sort((a, b) => a.sort - b.sort)
              .map(({ value }) => value)
              .map((item, index) => ({
                type: "img",
                src: `/${bucket_name}/${item.key}`,
                alt: item.key,
                id: index,
              }));
            setPhotos(data);
          });
        break

      default:
        setPhotos(
          localPhotos
            .concat(localPhotos)
            .concat(localPhotos)
            .concat(localPhotos)
            .concat(localPhotos)
            .concat(localPhotos)
            .concat(localPhotos)
            .concat(localPhotos)
            .concat(localPhotos)
            .map((value) => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value)
            .map((item, index) => ({
              type: "img",
              src: item.key,
              alt: item.key,
              id: index,
              loaded: false,
            }))
        );
    }
  }, [bucket_name]);

  return (
    <>
      <Typography align="center" variant="h3" color="secondary.main">
        {title}
      </Typography>
      <Lightbox
        open={selectedPhoto !== null}
        close={() => setSelectedPhoto(null)}
        slides={photos.map((item) => ({ src: item.src }))}
        index={selectedPhoto}
      />
      <ImageList variant="masonry" cols={matches ? 3 : 2} gap={16}>
        {photos.map((photo) => (
          <Image key={photo.id} photo={photo} onClick={() => setSelectedPhoto(photo.id)} />
        ))}
      </ImageList>
    </>
  );
}

function Image({ photo, onClick }) {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <ImageListItem className="imageHover" key={photo.id} onClick={onClick}>
      <img
        className="imageHover"
        key={photo.id}
        src={photo.src}
        alt={photo.alt}
        style={{
          borderRadius: 20,
          display: isLoaded ? "block" : "none",
        }}
        onLoad={() => setIsLoaded(true)}
      />
      {!isLoaded && (
        <Skeleton
          style={{ borderRadius: 20 }}
          variant="rectangular"
          height={300 + (Math.random() < 0.5 ? -1 : 1) * (Math.random() * 150)}
        />
      )}
    </ImageListItem>
  );
}
