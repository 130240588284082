import { Container, Grid2, Typography, useMediaQuery } from "@mui/material";
import Kira from "images/wedding_party/Kira.jpeg";
import Adam from "images/wedding_party/Adam.jpeg";
import Danielle from "images/wedding_party/Danielle.jpeg";
import Rebecca from "images/wedding_party/Rebecca.jpeg";
import Michael from "images/wedding_party/Michael.jpeg";
import Carissa from "images/wedding_party/Carissa.jpg";
import Lucas from "images/wedding_party/Lucas.jpeg";
import Apollo from "images/wedding_party/Apollo.jpg";
import Brandon from "images/wedding_party/Brandon.jpg";
import { useTheme } from "@emotion/react";

const ringBear = { image: Apollo, name: "Apollo", text: "Just another excuse to show you Apollo" }

const meganWeddingPartyList = [
  { image: Rebecca, name: "Rebecca Koehn (Maid of Honor)", text: "Megan and Rebecca met playing soccer in Junior High. Megan was originally a little afraid of Rebecca, but eventually wore her down. Now they are biffels for life, where Rebecca is her most loyal and loving friend. Like Megan, she also has a useless biology undergraduate degree and veered into an arts degree. That being said, Rebecca is a much better writer, getting her masters in creative writing. Their friendship has been passed down to their dogs Wattney and Apollo who cannot be restrained at the sight of each other at the dog park.", },
  { image: Adam, name: "Adam Charron", text: "Adam is Megan’s big brother. They are lucky that their relationship survived early on with constant wrestling matches, scratching attacks, and phone calls to their mom while she was at work. Nowadays he’s living his best life far far away from her, helping make movies and tv shows in Vancouver. Megan’s favourite thing about him is how easy it is to be regaled by his storytelling about even the most mundane things turn into epic adventures. Ask him about D&D, craft beer, and how popcorn should always have salt and vinegar seasoning." },
  { image: Kira, name: "Kira Shave", text: "Megan and Kira seem to be attached at the hip, at least when talking about where they live. Megan’s first apartment was a 10 minute walk from Kira’s, but unfortunately Kira decided to be an adult and buy a house far away. This made Megan sad but luckily a long year later she was able to buy a house only one neighbourhood away and all is right again. They bond over playing soccer, being introverted gossipers, and of course petting every animal they can get their hands on. Bring Kira on your next vacation if you’d like to be taken care of, or at least invite her to your party if you’d like some delicious puff wheat squares." },
  { image: Danielle, name: "Danielle Pocklington", text: "Megan met Danielle when they both worked selling shoes at Atmosphere. Ask any two people in our friend group how they know each other and the likely answer is “oh we both know Danielle, she introduced us”. Megan (and Niko) is no exception and will likely owe Danielle forever for bringing her into the friend group. She’ll be calm and cool telling you crazy ER nursing stories but get her on a soccer field and she will bring out her intense side. You’ll catch her being the first and last person on the dance floor at the wedding." }
]
const nikoWeddingPartyList = [
  { image: Carissa, name: "Carissa Cheng (Best Woman)", text: "Niko and Carissa became friends through nights on Whyte Ave. Though it started with nights drinking and going out to bars, their friendship lasted through the years and through the fall of a friend group. A few years ago Carissa had the audacity to move to Calgary but luckily for Niko she is great at making sure to check in and catch up. A quick “hey, how’s it going” text soon becomes a couple hour long catch up call about all the things going on in their lives. Niko’s favorite story with Carissa is the time she took him to Coco’s Bubble Tea, sparing some details the crux of the story is: grass jelly is not the same as green jelly. Carissa is extremely loyal to her friends and family and is always there when you need her. Make sure to ask Carissa to see a picture of Rain, you will not regret it." },
  { image: Michael, name: "Michael Shave", text: "Niko and Michael became good friends over their love of switching from hobby to hobby and investing all their time, money, and effort into the said new hobby. Whether it’s skiing in Golden, camping in Maui, or a quick trip to Home Depot for their latest project, Michael is always down for anything at a moments notice. If you’re lucky you’ll come out of the experience immortalized in one of Michael's infamous stories, of which you will be able to tell what percent of the story is actually true (usually around 60%). Make sure to chat with Michael about any YouTube video you have seen with less than 1k views, he’s guaranteed to have seen it." },
  { image: Brandon, name: "Brandon Glowatski", text: "Niko and Brandon met the way any gym bros meet at the gym, ignore each other for months, proceed to head nods acknowledging each others existence, then finally asking for a spot on each others set. Little did they know they had many, many things in common. Both of them work as remote software developers and love to nerd out on their respective personal tech projects. They share their horror stories of their partners “vegetarian diet” that mostly consist of crackers and cheese. And more recently they’ve bonded over their love/hate relationship with owning and maintaining a home. Brandon has a contagious positivity that makes it really hard to not have a good time when hanging out with him. Congratulate Brandon on becoming a dad when you see him at our wedding!" },
  { image: Lucas, name: "Lucas Somos", text: "Lucas is Niko’s younger brother. Like Megan and Adam, Lucas and Niko were at odds growing up, often fighting over video games or mini stick games. But as they both got older, and more importantly didn’t live together anymore, their relationship started getting better. Over time they have both picked up the same hobbies giving them many avenues to compete against each other. First it was soccer, where they now play together on a team both fighting to score more than the other. Then it was computers, both building their first PC’s during covid and both getting jobs in tech. Most recently Lucas has joined Niko in learning how to play hockey, luckily a few years later so Niko can still confidently say he’s the better player. It is pretty much guaranteed to see Lucas and Niko competing at some game or sport before or after the wedding." }
]

export default function WeddingParty() {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down("md"))

  const weddingPartyItem = (image, name, text, index) => {
    if (!isSmall) {
      return <WeddingPartyItem key={name} image={image} name={name} text={text} pictureFirst={index % 2 === 0} />
    } else {
      return <WeddingPartyItemSmall key={name} image={image} name={name} text={text} />
    }
  }

  return <>
    <Typography variant="h3" color="secondary" align="center" gutterBottom>
      Wedding Party
    </Typography>
    <Typography variant="h4" color="secondary.light" align="center" gutterBottom >Bridal Party</Typography>
    <Grid2 container spacing={4} sx={{ marginBottom: 8 }}>
      {meganWeddingPartyList.map(({ image, name, text }, index) => weddingPartyItem(image, name, text, index))}
    </Grid2>
    <Typography variant="h4" color="secondary.light" align="center" gutterBottom >Groom's Party</Typography>
    <Grid2 container spacing={4} sx={{ marginBottom: 8 }}>
      {nikoWeddingPartyList.map(({ image, name, text }, index) => weddingPartyItem(image, name, text, index))}
    </Grid2>
    <Typography variant="h4" color="secondary.light" align="center" gutterBottom >Ring Bearer</Typography>
    <Grid2 container spacing={4} sx={{ marginBottom: 8 }}>
      {weddingPartyItem(ringBear.image, ringBear.name, ringBear.text, 0)}
    </Grid2>
  </>;
}

function WeddingPartyItem({ image, name, text, pictureFirst }) {
  text = <Grid2 size={8}>
    <Typography variant="h5" color="secondary" gutterBottom align={pictureFirst ? "left" : "right"}>{name}</Typography>
    <Typography variant="body1" color="primary.contrastText" align={"justify"} sx={{ fontWeight: "500" }}>{text}</Typography>
  </Grid2>

  image = <Grid2 size={4}>
    <img alt={name} src={image} style={{ borderRadius: 20, width: "100%" }} />
  </Grid2>

  if (pictureFirst) {
    return <>{image}{text}</>
  } else {
    return <>{text}{image}</>
  }
}
function WeddingPartyItemSmall({ image, name, text }) {
  return (<Container align="center">
    <Grid2 size={9}>
      <img alt={name} src={image} style={{ borderRadius: 20, width: "100%" }} />
    </Grid2>
    <Grid2 size={10}>
      <Typography variant="h5" color="secondary" gutterBottom>{name}</Typography>
      <Typography variant="body1" color="primary.contrastText" align={"justify"} sx={{ fontWeight: "500" }}>{text}</Typography>
    </Grid2>
  </Container>)
}
