import { Container, Typography, Link } from "@mui/material";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

export default function Directions() {
  return (
    <>
      <Typography variant="h3" color="secondary" align="center" gutterBottom>
        Teen Time Ranch
      </Typography>
      <Typography
        variant="h4"
        color="secondary.light"
        align="center"
        gutterBottom
      >
        Range Road 253 Division No 13, Dapp, AB T0G 0S0
      </Typography>
      <Typography
        variant="h4"
        color="primary.contrastText"
        align="center"
        gutterBottom
      >
        1h45m Drive from Edmonton
      </Typography>
      <Container sx={{ marginBottom: 1 }}>
        <MapContainer
          center={[54.490811904677194, -113.71278223537635]}
          zoom={6}
          scrollWheelZoom={false}
          style={{ borderRadius: 20, width: "100%" }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={[54.490811904677194, -113.71278223537635]}>
            <Popup>
              <Typography variant="h6" color="secondary" align="center">
                Teen Time Ranch
              </Typography>
            </Popup>
          </Marker>
        </MapContainer>
      </Container>
      <div
        style={{
          marginBottom: 64,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Link
          href="https://www.google.com/maps?saddr=My+Location&daddr=54.490811904677194, -113.71278223537635"
          underline="hover"
          target="_blank"
          rel="noopener"
          variant="h5"
          color="secondary.light"
        >
          Google Maps
        </Link>
      </div>
    </>
  );
}
