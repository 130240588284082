import { Container, Typography } from "@mui/material";

export default function ErrorPage() {
  return (
    <Container sx={{ marginTop: "30vh" }}>
      <Typography variant="h1" color="primary.contrastText" align="center">
        Oops! This Page Doesn't Exist!
      </Typography>
    </Container>
  );
}
