import logo from "images/logo_with_text_background.png";
import { Box, Typography } from "@mui/material";

export default function TheInvitation() {
  return (
    <>
      <Typography align="center" variant="h3" color="secondary.main">
        We are excited to invite you to our wedding!
      </Typography>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img alt="Camp Somos" src={logo} style={{ width: "60%" }}></img>
      </Box>

      <Typography align="center" variant="h4" color="secondary.light">
        September 6, 2025
      </Typography>
    </>
  );
}
