import { Typography, Link } from "@mui/material";
import { getOverrides, MuiMarkdown } from 'mui-markdown';

export default function Accommodations() {

  const h3Item = (props) => <Typography variant="h4" color="secondary.light" align="center" gutterBottom>{props.children}</Typography>
  const h4Item = (props) => <Typography variant="h5" color="secondary" align="center" gutterBottom>{props.children}</Typography>
  const h5Item = (props) => <Typography variant="h6" color="primary.contrastText" align="center" gutterBottom sx={{ fontWeight: "400" }}
  >{props.children}</Typography>
  const linkItem = (props) => <Link href={props.href} target="_blank" variant="h6" color="primary.contrastText" align="center" gutterBottom sx={{ fontWeight: "600" }}
  >{props.children}</Link>

  return <>
    <Typography variant="h4" color="secondary" align="center" gutterBottom>
      Overnight accommodation
    </Typography>
    <MuiMarkdown overrides={{ ...getOverrides({}), h3: { component: h3Item }, h4: { component: h4Item }, h5: { component: h5Item }, a: { component: linkItem } }}>
      {`
##### We are very excited to be able to provide space for everyone to sleep at the venue after the wedding!

### Rules
##### You must bring your own bedding (sheets, pillow, sleeping bag or blanket)
##### We cannot guarantee any specific requests about sleeping location, but we will do our best
##### If you are drinking, please do not drive. There is lots of space and we will provide breakfast for you.
##### ***DO NOT PLAN TO TAKE A TAXI/UBER HOME, THEY DO NOT OPERATE AT THE VENUE***
<br/>
### Makewin Lodge
##### 14 dorm rooms
##### 2 bunkbeds per dorm room
##### 1 bed per dorm room can be converted to a double bed
<br/>
### Cabins
##### 8 cabins
##### 6 bunkbeds per cabin (sleeps 12)
##### Cabins are ***NOT*** heated
##### Washrooms and Showers are located in the Makewin Lodge
<br/>
### Tenting
##### There is lots of space to set up a tent
##### You must bring your own tent
<br/>
### Car Camping / RV
##### There are limited parking spots
##### Please let us know in advance so we can accommodate
<br/>
### Hotels
#### Edmonton is a 1 hour 45 min drive
#### Westlock is the closest town with hotels, ~35 min drive
##### [Westlock Inn & Conference Centre](https://maps.app.goo.gl/KwannjtPWQSuzxHRA)
##### [Ramada by Wyndham Westlock](https://maps.app.goo.gl/k2ngohk6Dq5LiArYA)
##### [All Stay Suites](https://maps.app.goo.gl/8Wmwr5V19zcJ23RX8)
<br/>
`}
    </MuiMarkdown>
  </>;
}
