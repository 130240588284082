import { CancelOutlined, Close } from "@mui/icons-material";
import {
  TextField,
  Typography,
  FormControl,
  Paper,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  FormHelperText,
  Select,
  MenuItem,
  InputLabel,
  Grid2,
  InputAdornment,
  IconButton,
  Collapse,
  Alert,
} from "@mui/material";
import { FieldArray, Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function TheRSVP() {
  const [errorSubmitting, setErrorSubmitting] = useState(false)

  const initialState = { names: [""], email: "", rsvp: "", mail_address: "", dietary_restrictions: [""], sleeping_arrangement_preference: "", comments: "" }
  const navigate = useNavigate();

  const validate = values => {
    const errors = {};

    const name_errors = values.names.map((name) => { if (name === "") { return 'Required' } else { return false } })

    if (name_errors.some(err => err)) { errors.names = name_errors }

    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }

    if (!values.rsvp) { errors.rsvp = 'Required' }
    else if (values.rsvp === "true") {
      if (!values.mail_address) { errors.mail_address = "Required" }
      if (!values.sleeping_arrangement_preference) { errors.sleeping_arrangement_preference = "Required" }
    }

    return errors;
  }

  const handleFormSubmit = (values, actions) => {
    console.log(values)
    console.log(actions)
    fetch("/rsvp/submit", { method: "POST", body: JSON.stringify(values) }).then((res) => {
      if (res.ok) {
        navigate("submitted")
      } else {
        actions.setSubmitting(false)
        setErrorSubmitting(true)
      }
    });
  }

  return (
    <>
      <Typography align="center" variant="h3" color="secondary.main">
        The RSVP
      </Typography>

      <Paper sx={{ margin: "auto", padding: 4, maxWidth: 500 }}>
        <Formik initialValues={initialState} validate={validate} onSubmit={handleFormSubmit}>
          {({ values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <Grid2 container spacing={2}>
              <FieldArray name="names">
                {arrayHelpers => (
                  <>
                    {values.names.map((name, index) => (
                      <Grid2 size={12} key={index}>
                        <FormControl fullWidth>
                          <TextField
                            color="secondary"
                            variant="standard"
                            label="Full Name"
                            id={`names.${index}`}
                            value={name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.names && touched.names[index] && errors.names && Boolean(errors.names[index])}
                            helperText={touched.names && touched.names[index] && errors.names && errors.names[index]}
                            fullWidth
                            slotProps={
                              index !== 0 ? {
                                input: {
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton size="large" color="secondary" onClick={() => arrayHelpers.remove(index)}>
                                        <CancelOutlined />
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }
                              } : {}}
                          />
                        </FormControl>
                      </Grid2>
                    ))}
                    {values.names.length < 10 && <Grid2 size={12}><Button fullWidth variant="outlined" onClick={() => arrayHelpers.push("")}>Add Person (Include Children!)</Button></Grid2>}
                  </>
                )}
              </FieldArray>
              <Grid2 size={12}>
                <FormControl fullWidth>
                  <TextField
                    label="Email"
                    id="email"
                    variant="standard"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </FormControl>
              </Grid2>
              <Grid2 display="flex" justifyContent="center" alignItems="center" size={12}>
                <FormControl error={touched.rsvp && Boolean(errors.rsvp)}>
                  <RadioGroup
                    value={values.rsvp}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <FormControlLabel labelPlacement="end" name="rsvp" value="true" control={<Radio />} label={values.names.length === 1 ? "I am coming! 😀" : "We are coming! 😀"} />
                    <FormControlLabel labelPlacement="end" name="rsvp" value="false" control={<Radio />} label={values.names.length === 1 ? "I can't make it 🥲" : "We can't make it 🥲"} />
                  </RadioGroup>
                  {touched.rsvp && Boolean(errors.rsvp) && <FormHelperText>{errors.rsvp}</FormHelperText>}
                </FormControl>
              </Grid2>
              {values.rsvp === "true" && (
                <>
                  <Grid2 size={12}>
                    <FormControl fullWidth>
                      <TextField
                        label="Mailing Address"
                        variant="standard"
                        id="mail_address"
                        multiline
                        minRows={2}
                        maxRows={4}
                        value={values.mail_address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.mail_address && Boolean(errors.mail_address)}
                        helperText={touched.mail_address && errors.mail_address}
                      />
                    </FormControl>
                  </Grid2>
                  <FieldArray name="dietary_restrictions">
                    <>
                      {values.names.map((name, index) => (name !== "" &&
                        <Grid2 size={12} key={index}>
                          <FormControl fullWidth>
                            <TextField
                              label={`${name} Dietary Restrictions`}
                              id={`dietary_restrictions.${index}`}
                              variant="standard"
                              multiline
                              minRows={2}
                              maxRows={4}
                              value={values.dietary_restrictions[index]}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.dietary_restrictions && touched.dietary_restrictions[index] && errors.dietary_restrictions && Boolean(errors.dietary_restrictions[index])}
                              helperText={touched.dietary_restrictions && touched.dietary_restrictions[index] && errors.dietary_restrictions && errors.dietary_restrictions[index]}
                            />
                          </FormControl>
                        </Grid2>
                      ))}
                    </>
                  </FieldArray>
                  <Grid2 size={12}>
                    <FormControl fullWidth variant="standard" error={touched.sleeping_arrangement_preference && Boolean(errors.sleeping_arrangement_preference)}>
                      <InputLabel id="sleeping_arrangement_preference_label">Sleeping arrangement Preference</InputLabel>
                      <Select
                        id="sleeping_arrangement_preference"
                        name="sleeping_arrangement_preference"
                        labelId="sleeping_arrangement_preference_label"
                        label="Sleeping Arrangement Preference"
                        value={values.sleeping_arrangement_preference}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <MenuItem value="dorm">Bunkbed in Dorm Room</MenuItem>
                        <MenuItem value="cabin">Bunkbed in Cabin</MenuItem>
                        <MenuItem value="tent">Tent</MenuItem>
                        <MenuItem value="rv">RV</MenuItem>
                        <MenuItem value="car">Car Camping</MenuItem>
                        <MenuItem value="other">I'll find my own</MenuItem>
                      </Select>
                      {touched.sleeping_arrangement_preference && Boolean(errors.sleeping_arrangement_preference) && <FormHelperText>{errors.sleeping_arrangement_preference}</FormHelperText>}
                    </FormControl>
                  </Grid2>
                </>
              )}
              <Grid2 size={12}>
                <FormControl fullWidth>
                  <TextField
                    label="Other Comments"
                    variant="standard"
                    id="comments"
                    multiline
                    minRows={2}
                    maxRows={4}
                    value={values.comments}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.comments && Boolean(errors.comments)}
                    helperText={touched.comments && errors.comments} />
                </FormControl>
              </Grid2>
              <Grid2 size={12}>
                <Button fullWidth variant="contained" color="secondary" onClick={handleSubmit} disabled={isSubmitting}>Submit</Button>
              </Grid2>
              <Grid2 size={12}>
                <Collapse in={errorSubmitting}>
                  <Alert
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => { setErrorSubmitting(false) }}
                      >
                        <Close fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{ mb: 2 }}
                  >
                    Something went wrong! Please try again
                  </Alert>
                </Collapse>
              </Grid2>
            </Grid2>
          )}
        </Formik >
      </Paper>
    </>
  );
}
