import { Container } from "@mui/material";
import NavigationBar from "components/NavigationBar";
import { Outlet } from "react-router-dom";

export default function Layout() {
  return (
    <>
      <NavigationBar />
      <Container sx={{ marginTop: 4, marginBottom: 8 }}>
        <Outlet />
      </Container>
    </>
  );
}
