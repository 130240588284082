import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { useState } from "react";

const FAQS = [
  { question: "What date should I RSVP by?", answer: "Please RSVP by July 6, 2025" },
  { question: "Am I allowed to bring my children?", answer: "Yes, please include their names when submitting your RSVP" },
  { question: "Am I allowed to bring my dog?", answer: "As much as we would love to be surrounded by dogs, the venue has only allowed us to have a small number of dogs. It pains us to say it, but please leave them at home" },
  { question: "Am I allowed to bring a +1?", answer: "Please do not bring a +1 unless they are specified on your invitation" },
  { question: "Where can I find a link to the wedding registry?", answer: 'We prefer you contribute to our "Apollo Needs to Eat" fund. If you prefer a registry, please check back here by January 2025 and we will post a link' },
  { question: "What time should I arrive?", answer: <>Please arrive no earlier than 3:30pm and no later than 4:15pm. The ceremony will begin at 4:30pm<br /><a href="/the-wedding/itinerary" target="_blank">Please refer to the Itinerary</a></> },
  { question: "Will I need to pay for my own drinks?", answer: "We will be running an open bar but tips for the bartenders are appreciated" },
  { question: "What is the dress code?", answer: <>Yes, <a href="https://www.brides.com/cocktail-attire-wedding-4844364" target="_blank" rel="noreferrer">Cocktail Attire</a> please. Ceremony will be outside (weather permitting) so adjust accordingly (i.e. probably no stilettos)</> },
  { question: "What are the sleeping arrangement options?", answer: <a href="/the-venue/accommodations" target="_blank">Please refer to Accommodations</a> },
  { question: "What are the transportation options?", answer: "There are no Taxi/Ride Share options operating from/to the venue. If you plan to drink, plan to stay the night or arrange for a designated driver to take you home." },
  { question: "Where should I park?", answer: <>There is a main parking lot at the Lodge as well as overflow parking across the street beside the barn. We request all guests try to carpool if possible to avoid issues with parking<br /><a href="/the-venue/camp-map" target="_blank">Please refer to the Camp Map</a></> },
  { question: "I have a question that is not here...", answer: "If you're invited to our wedding you should be able to just ask us..." }
]

export default function FAQs() {
  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Typography variant="h3" color="secondary" align="center" gutterBottom>
        FAQ's
      </Typography>
      {FAQS.map(({ question, answer }, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
        >
          <AccordionSummary><Typography variant="h5" color="primary">{question}</Typography></AccordionSummary>
          <AccordionDetails><Typography variant="body1" color="secondary">{answer}</Typography></AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}
