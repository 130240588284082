import {
  Timeline,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  TimelineContent,
} from "@mui/lab";
import { Typography, useMediaQuery } from "@mui/material";

const timelineItems = [
  { day: "saturday", time: "3:30 - 4:15 pm", description: "Guest Arrival + Bunk Assignment" },
  { day: "saturday", time: "4:30 - 5:00 pm", description: "Ceremony" },
  { day: "saturday", time: "5:00 - 6:00 pm", description: "Cocktail Hour + Games" },
  { day: "saturday", time: "6:00 pm", description: "Dinner" },
  { day: "saturday", time: "7:30 pm", description: "Speeches" },
  { day: "saturday", time: "8:00 pm", description: "Dance / Fire" },
  { day: "sunday", time: "10:00 am", description: "Breakfast" },
  { day: "sunday", time: "2:00 pm", description: "Wedding Over - Please Leave" },
];

export default function Itinerary() {
  const itemSpace = 5;

  const matches = useMediaQuery("(min-width:600px)");

  return (
    <>
      <Typography align="center" variant="h3" color="secondary.main">
        Itinerary
      </Typography>
      <Typography align="center" variant="h4" color="secondary.light">
        Saturday
      </Typography>
      <Timeline>
        {timelineItems
          .filter((item) => item.day === "saturday")
          .map((item, index, arr) => (
            <TimelineItem key={index} sx={{ marginTop: index === 0 ? 0 : itemSpace }}>
              <TimelineOppositeContent>
                <Typography
                  variant={matches ? "h4" : "h6"}
                  color="primary.contrastText"
                  sx={{ fontWeight: "600" }}
                >
                  {item.time}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                {item.icon || (
                  <TimelineDot color="secondary" sx={{ my: 2 }} />
                )}
                {index !== arr.length - 1 && (
                  <TimelineConnector sx={{ marginBottom: -itemSpace }} />
                )}
              </TimelineSeparator>
              <TimelineContent>
                <Typography
                  variant={matches ? "h4" : "h6"}
                  color="primary.contrastText"
                  sx={{ fontWeight: "600" }}
                >
                  {item.description}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
      </Timeline>

      <Typography align="center" variant="h4" color="secondary.light">
        Sunday
      </Typography>
      <Timeline>
        {timelineItems
          .filter((item) => item.day === "sunday")
          .map((item, index, arr) => (
            <TimelineItem key={index}  sx={{ marginTop: index === 0 ? 0 : itemSpace }}>
              <TimelineOppositeContent color="text.secondary">
                <Typography
                  variant={matches ? "h4" : "h6"}
                  color="primary.contrastText"
                  sx={{ fontWeight: "600" }}
                >
                  {item.time}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                {item.icon || (
                  <TimelineDot color="secondary" sx={{ my: 2 }} />
                )}
                {index !== arr.length - 1 && (
                  <TimelineConnector sx={{ marginBottom: -itemSpace }} />
                )}
              </TimelineSeparator>
              <TimelineContent>
                <Typography
                  variant={matches ? "h4" : "h6"}
                  color="primary.contrastText"
                  sx={{ fontWeight: "600" }}
                >
                  {item.description}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
      </Timeline>
    </>
  );
}
