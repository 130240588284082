import * as React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "ColorTheme";
import {
  Navigate,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Layout from "Layout";
import Photos from "pages/the-couple/Photos";
import NavigationBar from "components/NavigationBar";
import OurStory from "pages/the-couple/OurStory";
import Itinerary from "pages/the-wedding/Itinerary";
import WeddingParty from "pages/the-wedding/WeddingParty";
import FAQs from "pages/the-wedding/FAQs";
import Accommodations from "pages/the-venue/Accommodations";
import CampMap from "pages/the-venue/CampMap";
import TheRSVP from "pages/the-rsvp/TheRSVP";
import RSVPSubmitted from "pages/the-rsvp/RSVPSubmitted";
import ErrorPage from "pages/ErrorPage";
import Directions from "pages/the-venue/Directions";
import TheInvitation from "pages/the-invitation/TheInvitation";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: (
      <>
        <NavigationBar />
        <ErrorPage />
      </>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="/the-invitation" />,
      },
      {
        path: "the-invitation",
        element: <TheInvitation />,
      },
      {
        path: "the-couple",
        children: [
          {
            index: true,
            element: <Navigate to="/the-couple/our-story" />,
          },
          {
            path: "our-story",
            element: <OurStory />,
          },
          {
            path: "our-photos",
            element: (
              <Photos bucket_name={"wedding_photos"} title="Our Photos" />
            ),
          },
          {
            path: "our-escape-rooms",
            element: (
              <Photos
                bucket_name={"escape_room_photos"}
                title="Our Escape Rooms"
              />
            ),
          },
        ],
      },
      {
        path: "the-wedding",
        children: [
          {
            index: true,
            element: <Navigate to="/the-wedding/itinerary" />,
          },
          {
            path: "itinerary",
            element: <Itinerary />,
          },
          {
            path: "wedding-party",
            element: <WeddingParty />,
          },
          {
            path: "faqs",
            element: <FAQs />,
          },
        ],
      },
      {
        path: "the-venue",
        children: [
          {
            index: true,
            element: <Navigate to="/the-venue/directions" />,
          },
          {
            path: "directions",
            element: <Directions />,
          },
          {
            path: "accommodations",
            element: <Accommodations />,
          },
          {
            path: "camp-map",
            element: <CampMap />,
          },
        ],
      },
      {
        path: "the-rsvp",
        children: [
          {
            index: true,
            element: <TheRSVP />,
          },
          {
            path: "submitted",
            element: <RSVPSubmitted />,
          }
        ]
      },
      {
        path: "*",
        element: <ErrorPage />,
      },
    ],
  },
]);

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </>
  );
}

export default App;
