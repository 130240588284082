import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import DiamondIcon from "@mui/icons-material/Diamond";
import HomeIcon from "@mui/icons-material/Home";
import PetsIcon from "@mui/icons-material/Pets";
import ApartmentIcon from "@mui/icons-material/Apartment";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Typography, useMediaQuery } from "@mui/material";

const icon_props = { color: "secondary", sx: { my: 1 }, fontSize: "large" };

const steps = [
  {
    date: "March 2014",
    title: "We met",
    description: "Megan had started working at the camping/hiking store, Atmosphere, when she was in high school and Niko joined a couple years later while they were both in University. After meeting Niko, Megan went on a trip with her friends where she told them that she hoped he would quit before she got back.",
  },
  {
    date: "July 2018",
    title: "Started dating... somehow",
    description: "Luckily, Niko stuck around and he eventually won Megan over with his immense charm convincing her to be friends. But it took 4 years, a lot of growing up, and the guarantee that Megan wouldn’t have to deal with Niko’s questionable work ethic for them to actually start dating.",
    icon: <FavoriteIcon {...icon_props} />,
  },
  {
    date: "April 2020",
    title: "Moved into our first apartment together",
    description: "The global pandemic sped up the couple’s timeline on this next step so they could be together 24/7, and they successfully moved in together, choosing an apartment they both loved and navigated the biggest challenge to their relationship to date: buying a couch together when they were broke.",
    icon: <ApartmentIcon {...icon_props} />,
  },
  {
    date: "April 2022",
    title: `Welcomed Apollo to our family`,
    description: "The biggest light of their lives, he’s only made things better, more fun, and chaotic and both Megan and Niko would easily go to save Apollo before either of them if they were trapped in a burning building. Apollo would happily murder both of them for a park day with his best friends, Wattney and Aurora.",
    icon: <PetsIcon {...icon_props} />,
  },
  {
    date: "January 2023",
    title: `Bought our home`,
    description: "Having experienced one too many diarrhea explosions from Apollo on the way to the elevator to let him outside, enough was enough. The couple was ready for backyards and renovations. Niko now spends all of his free time working on his hobbies and using the kitchen for elaborate dinners while Megan continues to use the microwave for the majority of her meals and cozies up on the couch.",
    icon: <HomeIcon {...icon_props} />,
  },
  {
    date: "February 2024",
    title: "Got Engaged",
    description: "During the couple’s trip to Japan, Niko decided he did in fact want to commit to the bit of loving Megan forever, which resulted in an easy yes on her part. The couple was promptly interrupted by a local Japanese man walking through their proposal, despite all of Niko’s efforts to ensure it was a private moment between them. They celebrated being together forever with 2 separate dinners at 2 restaurants, one where Megan got to enjoy vegan delights and the other where Niko had his coveted Wagyu steak. ",
    icon: <DiamondIcon {...icon_props} />,
  },
  {
    date: "September 2025",
    title: "Getting Married!",
    description: "The couple are excited to celebrate with their friends and family and are also using this as an excuse to force their loved ones into doing a bunch of activities and games with them.",
  },
];

export default function OurStory() {
  const matches = useMediaQuery("(min-width:600px)");

  const itemSpace = 20;

  return (
    <>
      <Typography align="center" variant="h3" color="secondary.main">
        Our Story
      </Typography>
      <Timeline>
        {steps.map((step, index) => (
          <TimelineItem key={index} sx={{ marginTop: index === 0 ? 0 : itemSpace }}>
            <TimelineOppositeContent>
              <Typography variant={matches ? "h4" : "h6"} color="secondary">
                {step.date}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              {step.icon || <TimelineDot color="secondary" />}
              {index !== steps.length - 1 && (
                <TimelineConnector sx={{ marginBottom: -itemSpace }} />
              )}
            </TimelineSeparator>
            <TimelineContent>
              <Typography
                variant={matches ? "h4" : "h6"}
                color="secondary.light"
              >
                {step.title}
              </Typography>
              <Typography
                variant={matches ? "h5" : "body1"}
                color="primary.contrastText"
                sx={{ fontWeight: "400" }}
              >
                {step.description}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </>
  );
}
