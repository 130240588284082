import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Container,
  Box,
  IconButton,
  MenuItem,
  Popper,
  Fade,
  ClickAwayListener,
  MenuList,
  List,
  Collapse,
  ListItemButton,
  ListItemText,
  SwipeableDrawer,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import logo from "images/logo_no_text_no_outline_no_background.png";
import MenuIcon from "@mui/icons-material/Menu";

const pages = [
  { title: "The Invitation", path: "the-invitation" },
  {
    title: "The Couple",
    path: "the-couple",
    pages: [
      { title: "Our Story", path: "our-story" },
      { title: "Our Photos", path: "our-photos" },
    ],
  },
  {
    title: "The Wedding",
    path: "the-wedding",
    pages: [
      { title: "Itinerary", path: "itinerary" },
      { title: "Wedding Party", path: "wedding-party" },
      { title: "FAQ's", path: "faqs" },
    ],
  },
  {
    title: "The Venue",
    path: "the-venue",
    pages: [
      { title: "Directions", path: "directions" },
      { title: "Accommodations", path: "accommodations" },
      { title: "Camp Map", path: "camp-map" },
    ],
  },
  { title: "The RSVP", path: "the-rsvp" },
];

export default function NavigationBar() {
  return (
    <AppBar color="secondary" position="sticky">
      <Toolbar>
        <SmallNavBar />
        <a href="/">
          <Container
            component="img"
            alt="Camp Somos"
            src={logo}
            href="/"
            sx={{
              height: 50,
              width: "auto",
            }}
          />
        </a>
        <Typography
          variant="h5"
          noWrap
          component="a"
          href="/"
          sx={{
            mr: 2,
            display: { xs: "flex", md: "none" },
            flexGrow: 1,
            color: "inherit",
            textDecoration: "none",
          }}
        >
          Camp Somos
        </Typography>
        <LargeNavBar />
      </Toolbar>
    </AppBar>
  );
}

function SmallNavBar() {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [expandedMenuItem, setExpandedMenuItem] = React.useState(null);

  const handleClick = (item) => {
    if (item === expandedMenuItem) {
      setExpandedMenuItem(null);
    } else {
      setExpandedMenuItem(item);
    }
  };

  const handleNavigate = (path) => {
    navigate(path);
    setMenuOpen(null);
    setExpandedMenuItem(null);
  };
  return (
    <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
      <IconButton
        size="large"
        aria-label="camp somos menu"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={() => setMenuOpen(true)}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        anchor="left"
        open={menuOpen}
        onOpen={() => { }}
        onClose={() => setMenuOpen(false)}
      >
        <Box sx={{ width: 250 }} role="presentation">
          <Typography variant="h5" color="secondary" sx={{ margin: 2 }}>
            Camp Somos
          </Typography>
          <List>
            {pages.map((page) => {
              if (page.pages) {
                return (
                  <div key={page.title}>
                    <ListItemButton onClick={() => handleClick(page.path)}>
                      <ListItemText>
                        <Typography variant="h6" color="primary">
                          {page.title}
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                    <Collapse
                      in={expandedMenuItem === page.path}
                      timeout="auto"
                    >
                      <List component="div">
                        {page.pages.map((subpage) => (
                          <ListItemButton
                            key={subpage.title}
                            sx={{ pl: 4 }}
                            onClick={() =>
                              handleNavigate(`${page.path}/${subpage.path}`)
                            }
                          >
                            <Typography variant="h6" color="primary">
                              {subpage.title}
                            </Typography>
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                  </div>
                );
              } else {
                return (
                  <ListItemButton
                    key={page.title}
                    onClick={() => handleNavigate(page.path)}
                  >
                    <Typography variant="h6" color="primary">
                      {page.title}
                    </Typography>
                  </ListItemButton>
                );
              }
            })}
            <ListItemButton
              key="Our Escape Rooms"
              onClick={() => handleNavigate("the-couple/our-escape-rooms")}
            >
              <Typography variant="h6" color="background.paper">
                Our Escape Rooms
              </Typography>
            </ListItemButton>
          </List>
        </Box>
      </SwipeableDrawer>
    </Box>
  );
}

function LargeNavBar() {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event, path) => {
    setAnchorElNav({ anchor: event.currentTarget, path: path });
  };

  const handleCloseNavMenu = (e) => {
    setAnchorElNav(null);
  };

  const handleNavigate = (path) => {
    navigate(path);
    setAnchorElNav(null);
  };

  return (
    <ClickAwayListener onClickAway={handleCloseNavMenu}>
      <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
        {pages.map((page) => {
          if (page.pages) {
            const open = (anchorElNav ?? {}).path === page.path;

            return (
              <div key={page.title}>
                <Button
                  id={page.title}
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={(event) => handleOpenNavMenu(event, page.path)}
                  sx={{ my: 2, display: "block", mr: 2 }}
                >
                  <Typography variant="h6">{page.title}</Typography>
                </Button>
                <Popper
                  id={page.title}
                  anchorEl={(anchorElNav ?? {}).anchor}
                  open={open}
                  placement="bottom-start"
                  // disablePortal
                  sx={{ zIndex: 1200 }}
                  transition
                >
                  {({ TransitionProps }) => (
                    <Fade
                      {...TransitionProps}
                      timeout={{ exit: 0, enter: 400 }}
                    >
                      <Box
                        sx={{
                          margin: 0,
                          padding: 1,
                          borderRadius: 2,
                          bgcolor: "background.paper",
                        }}
                      >
                        <MenuList>
                          {page.pages.map((subpage) => (
                            <MenuItem
                              key={subpage.title}
                              onClick={() =>
                                handleNavigate(`${page.path}/${subpage.path}`)
                              }
                            >
                              {subpage.title}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </Box>
                    </Fade>
                  )}
                </Popper>
              </div>
            );
          } else {
            return (
              <Button
                key={page.title}
                onClick={() => handleNavigate(page.path)}
                sx={{ my: 2, display: "block", mr: 2 }}
              >
                <Typography variant="h6">{page.title}</Typography>
              </Button>
            );
          }
        })}
        <Button
          id="Our Escape Rooms"
          onClick={() => handleNavigate("the-couple/our-escape-rooms")}
        >
          <Typography color="secondary.main" variant="h6">
            Our Escape Rooms
          </Typography>
        </Button>
      </Box>
    </ClickAwayListener>
  );
}
